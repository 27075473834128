/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React, { useRef } from "react";
import loadable from "@loadable/component";
import { useAppState } from "@state";
import useRomeroFooterData from "@staticQueries/romero/FooterQuery";
import { m, AnimatePresence } from "framer-motion";
import filterActions from "@utils/filterActions";

const RomeroFooter = loadable(() => import("@organisms/RomeroFooter"));
const Cta = loadable(() => import("@organisms/Cta"));

const PageContainer = ({ children, className, path }) => {
  const [{ layout, ea }] = useAppState();
  const { footerNav, actions } = useRomeroFooterData();

  const upNext = filterActions(actions, ea.activistCodes || []);

  const action = upNext.length >= 1 ? upNext[0] : actions[actions.length - 1];
  const { template } = layout;
  const isAction = template === "action";
  const membership = template === "membershipRomero";

  const page = useRef();

  const entered = true;

  return (
    <m.div
      ref={page}
      id="ac-layout"
      initial={{ opacity: 0, y: "10rem" }}
      animate={{ opacity: 1, y: "0rem" }}
      exit={{ opacity: 0, y: "-10rem" }}
      transition={{ duration: 0.5 }}
      className={`
      ${className}
      ${!entered ? "opacity-0" : ""}
      flex min-h-screen w-full flex-grow flex-col overflow-x-hidden overscroll-none bg-cream`}
    >
      <main className="relative">
        <AnimatePresence initial>{children}</AnimatePresence>
      </main>
      <footer className="mt-auto">
        {!isAction && !membership && action && (
          <div>
            <Cta
              heading={action.heading}
              copy={action.copy}
              backgroundImage={action.backgroundImage}
              action={{ cta: "Become a Member", url: "/membership" }}
              showAction
              path={path}
            />
          </div>
        )}
        <RomeroFooter nav={footerNav} />
      </footer>
    </m.div>
  );
};

PageContainer.defaultProps = {
  className: "",
};

export default PageContainer;
